const photo = new URL("../image/2.webp", import.meta.url);
const photo3 = new URL("../image/3.webp", import.meta.url);
const photo4 = new URL("../image/4.webp", import.meta.url);
const photo5 = new URL("../image/5.webp", import.meta.url);
const photo6 = new URL("../image/6.webp", import.meta.url);
const photo7 = new URL("../image/7.webp", import.meta.url);
const photo8 = new URL("../image/8.webp", import.meta.url);
const photo10 = new URL("../image/12.webp", import.meta.url);

export const photos = [
    {
        link: photo,
        name: "фото стоматологии"
    },
    {
        link: photo3,
        name: "фото стоматологии"
    },
    {
        link: photo4,
        name: "фото стоматологии"
    },
    {
        link: photo5,
        name: "фото стоматологии"
    },
    {
        link: photo6,
        name: "фото стоматологии"
    },
    {
        link: photo7,
        name: "фото стоматологии"
    },
    {
        link: photo8,
        name: "фото стоматологии"
    },
    {
        link: photo10,
        name: "фото стоматологии"
    },
]

export const price_therapy = [
    {
        name: "Лечение кариеса (пломба на жевательной поверхности)",
        cost: "4 700₽"
    },
    {
        name: "Лечение кариеса (пломба на контактной поверхности)",
        cost: "4 900₽"
    },
    {
        name: "Лечение кариеса (реставрация фронтального зуба)",
        cost: "5 200₽"
    },
]

export const price_pulpitis = [
    {
        name: "Лечение пульпита: одноканальный зуб",
        cost: "от 8 800₽"
    },
    {
        name: "Лечение пульпита: двухканальный зуб",
        cost: "от 11 200₽"
    },
    {
        name: "Лечение пульпита: трехканальный зуб",
        cost: "от 14 000₽"
    },
    {
        name: "Оказание разовой помощи при острой боли",
        cost: "2 000₽"
    }

]

export const price_surgury = [

    {
        name: "Удаление зуба 1-й степени сложности",
        cost: "2 000₽"
    },
    {
        name: "Удаление зуба 2-й степени сложности",
        cost: "3 000₽"
    },
    {
        name: "Удаление зуба 3-й степени сложности",
        cost: "5 000₽"
    },


]
export const price_casts = [
    {
        name: " Металлокерамическая коронка",
        cost: "9 000₽"
    },
    {
        name: "Цельнолитая коронка",
        cost: "6 000₽"
    },
    {
        name: "Безметалловая коронка (ZrO2 E-max)",
        cost: "15 000₽"
    },
    {
        name: "Виниры E.max (+wax up) - 1 ед.",
        cost: "21 000₽"
    },
    {
        name: "Съемный пластмассовый протез",
        cost: "24 000₽"
    },
    {
        name: "Протез с эстетическими кламмерами",
        cost: "28 000₽"
    },
    {
        name: "Бюгельное протезирование",
        cost: "34 000₽"
    }
]
export const price_implant = [
    {
        name: "Коронка на импланте (полная анатомия, диоксид циркония)",
        cost: "25 000₽"
    },
    {
        name: "Коронка на импланте (циркониевый абатмент)",
        cost: "27 000₽"
    },
    {
        name: "Установка импланта Dentium ",
        cost: "25 000₽"
    }
]

export const price_other = [
    {
        name: "УЗ чистка зубного камня",
        cost: "2 800₽"
    },
    {
        name: "УЗ чистка зубного камня - 1 ед.",
        cost: "200₽"
    },
    {
        name: "Обезболивание (анестезия)",
        cost: "800₽"
    },
    {
        name: "Прицельный снимок",
        cost: "350₽"
    }
]

